import React, { Component } from 'react';
import authService from './api-authorization/AuthorizeService'
import './assets/css/style.css'

export class Home extends Component {
    static displayName = Home.name;

    constructor(props) {
        super(props);
        this.state = { token: null };
        this.populateWeatherData = this.populateWeatherData.bind(this);

    }

    componentDidMount() {
        this.populateWeatherData();
    }
    async populateWeatherData() {
        const token = await authService.getAccessToken();
        this.setState({ token: token });
    }
    render() {
        //var token = authService.getAccessToken().result;
        var result = <div>Hello</div>;
        result = this.state.token == null ?
            <body style={{ backgroundColor: "white" }}>
                <section id="hero">
                    <div className="container" style={{ marginTop: "-100px" }}>
                        <div className="row justify-content-between">
                            <div className="col-lg-12 pt-5 pt-lg-0 order-2 order-lg-1 d-flex align-items-center">
                                <div data-aos="zoom-out">
                                    <h1 className="text-center text-lg-start" style={{ color: "#444444" }}><span style={{ color: "#444444" }}>РУСАЛ</span> Красноярский алюминиевый завод</h1>
                                    <h2 style={{ color: "#444444" }}>Система оперативного определения пролива металла при разливке цилиндрических слитков на основе алгоритмов компьютерного зрения</h2>
                                    <div className="text-center text-lg-start">
                                    </div>
                                </div>
                            </div>                          
                        </div>
                        <div className="row">
                            <div className="text-center text-lg-start col-lg-4">
                                <a href="/authentication/login" className="btn-get-started scrollto">Войти</a>
                            </div>
                                <div className="col-lg-8 order-1 order-lg-2 hero-img" data-aos="zoom-out" data-aos-delay="300">
                                    <img src="/assets/img/NT.jpg" className="img-fluid animated" alt=""></img>
                        </div>
                        </div>
                    </div>                  

                </section>
            </body>
            :
            <div>
                <h1>Система определения пролива металла при разливке</h1>
                <p>Система оперативного определения пролива металла при разливке цилиндрических слитков на основе алгоритмов компьютерного зрения</p>
                <ul>
                    <li><a href='/fetch-data'>Анализ проливов</a></li>
                    <li><a href='/home/Download?type=0'>Документация</a></li>
                    <li><a href='/home/Download?type=1'>Презентация</a> </li>
                    <li><a href='/home/Download?type=2'>Вопросы для приёмки MVP_РУСАЛ</a></li>
                </ul>
                <p>Техническое задание</p>
                <ul>
                    <li><strong>ПРЕДПОСЫЛКИ</strong>. «РУСАЛ» — российская алюминиевая компания, один из крупнейших в мире производителей первичного алюминия и глинозёма. Следуя важнейшему приоритету компании — постоянному улучшению качества сервиса и оптимизации ресурсов, компания постоянно использует возможность современных технологий анализа данных и искусственного интеллекта с целью улучшения качества и оптимизации различных процессов компании.
                </li>
                    <li><strong>ЦЕЛИ ПРОЕКТА</strong>.
                          Построение системы оперативного определения пролива металла при разливке цилиндрических слитков на основе алгоритмов компьютерного зрения для оптимизации промышленных процессов компании.                </li>
                    <li><strong>СОДЕРЖЕНИЕ РАБОТ</strong>.
                        В рамках данного проекта требуется разработать алгоритмы детекции пролива металла при разливке цилиндрических слитков на основе технологий компьютерного зрения. В частности, определение зоны анализа, определение отверстий литейного стола и контроль цвета и пролива металла.
                        Алгоритм должен принимать на вход видеопоток/видео файл, а на выходе давать результаты по проливу металла. Формат результатов будет согласован в процессе формирования технического задания.
                        В рамках проекта подразумевается применение различных методов, архитектур и готовых моделей, реализованных компанией и решающих поставленную задачу, а также использование разметки данных с последующим обогащением информации.
                    </li>
                </ul>
            </div>;

        return (
            <div> {result}</div>
        );
    }
}
